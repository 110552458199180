const useGVerifyApi = async <T>(url: string | (() => string), options?: object) => {
  const config = useRuntimeConfig()

  return useFetch<T>(url, {
    baseURL: config.public.RECAPTCHA_BASE_URL,
    async onResponseError({ request, response }) {
      console.error('[fetch response error]', request, response.status, response.body)
    },
    ...options,
  })
}

export default useGVerifyApi
