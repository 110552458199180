import { defineStore } from 'pinia'
import type { CheckExistPayload, Gverif, OTPPayload } from '~/models/Auth'

// NOTE
// OTVCTYPE : login, register, verify & changeEmail

interface AuthState {
  socmedToken: string | null
  error: string | string[] | null
  otpPayload: OTPPayload
}

export const useAuth = defineStore('auth', {
  state: (): AuthState => ({
    socmedToken: null,
    error: null,
    otpPayload: {},
  }),

  actions: {
    setOTPPayload(val: OTPPayload) {
      // i create OTPPayload because passing value between nested component looks bad (ex: form -> OTPModal -> OTPForm)
      this.otpPayload = val
    },
    async sendOTP(recipientVal: string, typeVal: string, channelVal: string) {
      const { data, error } = await useCompanyApi('/talent/auth/otvc', {
        method: 'POST',
        body: {
          recipient: recipientVal,
          otvcType: typeVal,
          channel: channelVal,
        },
        initialCache: false,
      })
      const resp = data?.value as { success: boolean; message: string; error: string }
      if (!error.value && resp?.success === true)
        return { status: true, msg: resp.message }
      else
        return { status: false, msg: resp?.error || error?.value?.data?.error }
    },
    async setTokenAuth(tokenVal: string) {
      // SET NEW TOKEN
      const config = useRuntimeConfig()
      const expiredDate = new Date()
      expiredDate.setTime(expiredDate.getTime() + (30 * 24 * 60 * 60 * 1000))
      const token = useCookie('token', { path: '/', maxAge: 60 * 60 * 24 * 30, expires: expiredDate, domain: config.public.COOKIE_DOMAIN })

      token.value = tokenVal
    },
    async onContinueEmail(code: string) {
      // used in login and register
      const config = useRuntimeConfig()
      const redirect = useCookie('redirect', { path: '/', maxAge: 3600, domain: config.public.COOKIE_DOMAIN })
      const trackingParams = ref({ entry_method: 'Email', failed_reason: null })

      const { data, error } = await useCompanyApi('/talent/auth/continue/email', {
        method: 'POST',
        body: { email: this.otpPayload?.recipient, code },
        key: 'continue_email',
        initialCache: false,
      })
      const resp = data.value as { success: boolean; error: string; token: string; isNewUser: boolean }
      if (resp.success) {
        this.setTokenAuth(resp.token)
        useSetTracking(`${resp.isNewUser ? 'registration' : 'login'}_success`, trackingParams.value)

        await useSleep(1000)
        if (redirect.value)
          window.location.replace(redirect.value)
        else window.location.replace('/dashboard')
      }
      else {
        trackingParams.value.failed_reason = resp.error || error.value?.data?.error
        useSetTracking(`${resp.isNewUser ? 'registration' : 'login'}_failed`, trackingParams.value)
        return resp.error || error.value?.data?.error
      }
    },
    async onContinueSocial(providerVal: string, redirectTo: string, subdomain: string) {
      const trackingParams = ref({ entry_method: useTextCapitalize(providerVal), failed_reason: null })

      const config = useRuntimeConfig()
      const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })
      const { data, error } = await useCompanyApi('/talent/auth/continue/social', {
        method: 'POST',
        body: { token: this.socmedToken, provider: providerVal },
        key: 'continue_socmed',
        initialCache: false,
      })
      const resp = data.value as { success: boolean; error: string; token: string; isNewUser: boolean }
      if (resp.success) {
        this.setTokenAuth(resp.token)
        useSetTracking(`${resp.isNewUser ? 'registration' : 'login'}_success`, trackingParams.value)

        await useSleep(1000)
        region.value = subdomain
        if (redirectTo && redirectTo !== 'undefined')
          window.location.href = useSetSubdomain(subdomain) + redirectTo
        else
          window.location.href = `${useSetSubdomain(subdomain)}/dashboard`
      }
      else {
        trackingParams.value.failed_reason = resp.error || error.value?.data?.error
        useSetTracking(`${resp.isNewUser ? 'registration' : 'login'}_failed`, trackingParams.value)
        return resp.error || error.value?.data?.error
      }
    },
    async checkExist(valType: string, val: string, loggedIn: boolean) {
      const newParam = ref<CheckExistPayload>({ 'api-version': loggedIn ? 3 : 2 })
      if (valType === 'email')
        newParam.value.email = val
      else if (valType === 'phone')
        newParam.value.phone = val

      const { data, error } = await useCompanyApi('/talent/auth/isExist', {
        params: newParam.value,
        useAuth: loggedIn,
        key: 'check_inline_email_valid',
      })
      const resp = data?.value as { exist: boolean; authStrategy: string; error: string }
      if (!error.value)
        return { status: true, exist: resp.exist, authStrategy: resp.authStrategy }
      else
        return { status: false, msg: resp?.error || error?.value?.data?.error }
    },
    async checkCaptchaValid(token: string, withTracking?: boolean) {
      const config = useRuntimeConfig()
      const errorList = [
        { id: 'browser-error', value: 'Please try on epicareer instead' },
        { id: 'timeout-or-duplicate', value: 'Captcha expired, please refresh the page' },
        { id: 'bad-request', value: 'Server is currently down' },
        { id: 'low-score', value: 'Captcha failed to validate request' },
        { id: 'invalid-input-response', value: 'Captcha not loaded' },
      ]

      const { data } = await useGVerifyApi<Gverif>('/siteverify', {
        key: 'verify_google',
        initialCache: false,
        params: {
          secret: config.public.RECAPTCHA_SECRETKEY,
          response: token,
        },
      })

      const returnVal = ref()
      if (data.value.success && data.value.score < 0.5)
        returnVal.value = 'low-score'

      else if (!data.value.success)
        returnVal.value = data.value['error-codes'][0]

      if (withTracking && returnVal.value)
        useSetTracking('login_failed', { entry_method: 'Email', failed_reason: 'low-score' })

      return returnVal.value ? errorList.find(val => val.id === returnVal.value)?.value : null
    },
  },

  persist: {
    key: 'auth',
    storage: process.client ? window.localStorage : null,
  },
})
